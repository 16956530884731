// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Imports
import axios, { AxiosError } from 'axios';
import { errorToast } from '@universities/store';
import GetActivationAnalysisReq from '../types/GetActivationAnalysis';
import MetricResult from '../types/MetricResoult';
import AnalysisChartData from '../types/AnalysisChartData';

export const getActivationAnalysisByUniversity = createAsyncThunk('goin/getActivationAnalysis', async (req: GetActivationAnalysisReq) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/universities/${req.universityId}/plausible-analytics`, { params: req.params });
    return {
      data: response.data.results,
      resultType: req.params.resultType,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
    }
    return Promise.reject(err);
  }
});

export const activationAnalysisSlice = createSlice({
  name: 'activationAnalysis',
  initialState: {
    metrics: [] as MetricResult[],
    currentUniAnalysis: {} as AnalysisChartData,
  },
  reducers: {
    setCurrentMetric: (state, action) => {
      if (action.payload === 'visitors') {
        state.currentUniAnalysis.data = state.metrics.map(
          (result) => (result.visitors),
        );
      }
      if (action.payload === 'visits') {
        state.currentUniAnalysis.data = state.metrics.map(
          (result) => (result.visits),
        );
      }
      if (action.payload === 'bounce_rate') {
        state.currentUniAnalysis.data = state.metrics.map(
          (result) => (result.bounce_rate),
        );
      }
      if (action.payload === 'pageviews') {
        state.currentUniAnalysis.data = state.metrics.map(
          (result) => (result.pageviews),
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActivationAnalysisByUniversity.fulfilled, (state, action) => {
      if (action.payload.resultType === 'timeseries') {
        state.metrics = action.payload.data;
        state.currentUniAnalysis.data = state.metrics.map(
          (result) => (result.visitors),
        );
        state.currentUniAnalysis.labels = state.metrics.map(
          (result) => (result.date),
        );
      }

      if (action.payload.resultType === 'aggregate') {
        state.currentUniAnalysis.visitorsSum = action.payload.data.visitors.value;
        state.currentUniAnalysis.visitsSum = action.payload.data.visits.value;
        state.currentUniAnalysis.pageviewsSum = action.payload.data.pageviews.value;
        state.currentUniAnalysis.bounceRateSum = action.payload.data.bounce_rate.value;
      }
    });
  },
});

export const {
  setCurrentMetric,
} = activationAnalysisSlice.actions;

export default activationAnalysisSlice.reducer;
