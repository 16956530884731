import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  CycleRequest,
  CycleStats,
  CycleStatsRequest,
  CyclesState,
} from '../types/index';

const initialState: CyclesState = {
  cycles: [],
  cycleStats: {},
};

export const getCycles = createAsyncThunk(
  'goin/getCycles',
  async ({ universityId, isLatest }: CycleRequest) => {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_ENV}/universities/${universityId}/cycles`,
      { params: { isLatest } },
    );
    return response.data;
  },
);

export const getCycleStats = createAsyncThunk(
  'goin/getCycleStats',
  async ({ universityId, cycleId, params = '' }: CycleStatsRequest) => {
    const response = await axios.get(
      `${
        process.env.REACT_APP_URL_ENV
      }universities/${universityId}/cycles/${cycleId}/snapshots${
        params ? `?${params}` : ''
      }`,
      {},
    );
    return {
      data: response.data,
    };
  },
);

export const cyclesSlice = createSlice({
  name: 'cycles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCycles.fulfilled, (state, action) => {
      state.cycles = action.payload;
    });
    builder.addCase(getCycleStats.fulfilled, (state, action) => {
      state.cycleStats = action.payload?.data as CycleStats;
    });
  },
});

export default cyclesSlice.reducer;
