// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';
import { getUniversityGroups } from '@universities/components/edit/groups/store';

const initialState = {
  groups: [],
};

export const universityGroupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUniversityGroups.fulfilled, (state, action) => {
      state.groups = action?.payload?.data;
    });
  },
});

export default universityGroupsSlice.reducer;
