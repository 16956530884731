// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from 'axios';
import { deleteToast, errorToast, successToast } from '@universities/store';
import {
  ChatAnalysisResponse,
  GroupRequest, GroupsInitialState, ParsedGroupChatAnalysis, UniversityChatAnalysesResponse,
} from '../types';

/**
 * Takes the ranked list that AI model returned and parses it into an array of strings
 * @param input - Value that AI model returned
 */
export const parseAIResultToArray = (input: string): string[] => {
  let str = input;
  str = str.replace(/"/g, '');
  return str.split('\\n');
};

function parseSentimentResults(input: string): string[] {
  const lines = parseAIResultToArray(input);
  const result = lines.flatMap((line) => line.split(';').map((part) => part.trim()));
  return result.filter((value) => value !== '');
}

export const getGroupsImages = createAsyncThunk(
  'goin/getGroupsImages',
  async (_id: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_ENV}/universities/${_id}/groups/images`,
    );
    return {
      data: response.data,
    };
  },
);

// get groups
export const getUniversityGroups = createAsyncThunk(
  'goin/getUniversityGroups',
  async (_id: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_ENV}/universities/${_id}/groups`,
    );
    return {
      data: response.data,
    };
  },
);

export const getUniversityAnalyses = createAsyncThunk(
  'goin/getUniversityAnalyses',
  async ({ _id, sort, limit }: { _id: string, sort: string, limit: number }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_ENV}/universities/${_id}/chatAnalyses`,
      {
        params: {
          sort,
          limit,
        },
      },
    );
    return {
      data: response.data,
    };
  },
);

// update group
export const updateUniversityGroup = createAsyncThunk(
  'goin/updateUniversityGroup',
  async (req: GroupRequest) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_URL_ENV}/universities/${req.universityId}/groups/${req.cid}`,
        { url: req.url },
      );
      successToast('Successfully updated!');
      return {
        data: response.data,
      };
    } catch (err) {
      errorToast();
      return Promise.reject(err);
    }
  },
);

// delete group
export const deleteUniversityGroup = createAsyncThunk(
  'goin/deleteUniversityGroup',
  async ({ universityId, cid }: { universityId: string; cid: string }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL_ENV}/universities/${universityId}/groups/${cid}`,
      );
      deleteToast('Successfully deleted!');
      return {
        data: response.data,
      };
    } catch (err) {
      errorToast();
      return Promise.reject(err);
    }
  },
);

// get chat analysis of one group
export const getGroupChatAnalysis = createAsyncThunk(
  'goin/getGroupChatAnalysis',
  async ({ universityId, cid }: { universityId: string; cid: string }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_ENV}/universities/${universityId}/chatAnalyses/${cid}`,
      );
      return {
        data: response.data,
      };
    } catch (err) {
      errorToast();
      return Promise.reject(err);
    }
  },
);

const initialState: GroupsInitialState = {
  images: [],
  list: [],
  selectedGroupChatAnalysis: undefined,
  mostPopularGroupsAnalyses: [],
  mostActiveGroupsAnalyses: [],
};

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    selectGroupAnalysis: (state, action) => {
      const cid = action.payload;
      let selectedAnalysis = state.mostPopularGroupsAnalyses
        .find((analysis) => analysis.cid === cid);
      if (!selectedAnalysis) {
        selectedAnalysis = state.mostActiveGroupsAnalyses.find((analysis) => analysis.cid === cid);
      }
      state.selectedGroupChatAnalysis = selectedAnalysis;
    },
    resetAnalysisSelection: (state) => {
      state.selectedGroupChatAnalysis = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGroupsImages.fulfilled, (state, action) => {
      state.images = action?.payload?.data;
    });
    builder.addCase(getUniversityGroups.fulfilled, (state, action) => {
      state.list = action?.payload?.data;
    });
    builder.addCase(deleteUniversityGroup.fulfilled, (state, action) => {
      state.list = state.list.filter(
        (group) => group?.cid !== action?.meta?.arg?.cid,
      );
    });
    builder.addCase(getGroupChatAnalysis.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.selectedGroupChatAnalysis = action.payload.data;

        const { topicsResponse, sentimentResponse } = action.payload.data as ChatAnalysisResponse;
        const rankedTopics = parseAIResultToArray(topicsResponse);
        const sentiment = parseSentimentResults(sentimentResponse);

        if (state.selectedGroupChatAnalysis) {
          state.selectedGroupChatAnalysis.topics = rankedTopics;
          state.selectedGroupChatAnalysis.sentiment = sentiment;
        }
      }
    });
    builder.addCase(getUniversityAnalyses.fulfilled, (state, action) => {
      const { meta, payload } = action;
      if (payload.data) {
        const analyses = (payload.data as UniversityChatAnalysesResponse).map((chat) => {
          const { topicsResponse, sentimentResponse } = chat;
          const parsedResponse = parseAIResultToArray(topicsResponse);
          const sentiment = parseSentimentResults(sentimentResponse);
          return { ...chat, topics: parsedResponse, sentiment } as ParsedGroupChatAnalysis;
        });
        if (meta.arg.sort === 'membersCount') {
          state.mostPopularGroupsAnalyses = analyses;
        } else if (meta.arg.sort === 'messagesCount') {
          state.mostActiveGroupsAnalyses = analyses;
        }
      }
    });
  },
});

export const { selectGroupAnalysis, resetAnalysisSelection } = groupsSlice.actions;
export default groupsSlice.reducer;
