// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

// ** UseJWT import to get config
import useJwt from '../auth/jwt/useJwt';

import { getUniversityById } from '../views/apps/universities/store';

import { DashboardRole } from '../auth/utils';

const config = useJwt.jwtConfig;

const initialUser = () => {
  const item = window.localStorage.getItem('userData');
  //* * Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    isUsersModuleDisabled: false,
  },
  reducers: {
    update2FA: (state, action) => {
      state.userData.enable2FA = action.payload;
      localStorage.setItem('userData', JSON.stringify(state.userData));
    },
    handleLogin: (state, action) => {
      state.userData = action.payload;
      state.userData.accessToken = action.payload[config.storageTokenKeyName];
      state.userData.refreshToken = action.payload[config.storageRefreshTokenKeyName];
      localStorage.setItem('userData', JSON.stringify(action.payload));
      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken));
      localStorage.setItem(config.storageRefreshTokenKeyName,
        JSON.stringify(action.payload.refreshToken));
    },
    handleLogout: (state) => {
      state.userData = {};
      state.userData.accessToken = null;
      state.userData.refreshToken = null;
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData');
      localStorage.removeItem(config.storageTokenKeyName);
      localStorage.removeItem(config.storageRefreshTokenKeyName);
      localStorage.removeItem('disableUsersModule');
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUniversityById.fulfilled, (state, action) => {
      state.isUsersModuleDisabled = state.userData.role === DashboardRole.UNIVERSITY_ADMIN
       && (!action?.payload?.data?.permissions?.includes('dashboardUsers'));
      localStorage.setItem('disableUsersModule', state.isUsersModuleDisabled.toString());
    });
  },
});

export const { handleLogin, handleLogout, update2FA } = authSlice.actions;

export default authSlice.reducer;
