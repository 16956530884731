/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem('accessToken');
};

export const getUserData = () => JSON.parse(localStorage.getItem('userData') || '{}');

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate
 * to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability
 * then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const DashboardRole = {
  SUPER_ADMIN: 0,
  UNIVERSITY_ADMIN: 1,
};
export const isSuperAdmin = (role: number) => role === DashboardRole.SUPER_ADMIN;
export const isAdmin = (role: number) => role === DashboardRole.UNIVERSITY_ADMIN;

export const getHomeRouteForLoggedInUser = (userRole: string) => {
  if (isSuperAdmin(parseInt(userRole, 10))) return '/universites';
  if (isAdmin(parseInt(userRole, 10))) return `/my-university?uid=${getUserData().university}`;
  return { name: 'auth-login' };
};
