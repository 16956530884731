import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ModalWrapperProps from '../components/types/ModalWrapperProps';

const initialState:ModalWrapperProps = {
  open: false,
  Component: null,
  componentProps: {},
  title: '',
  bodyContent: undefined,
  subtitle: '',
  modalProps: {},
  modalHeaderProps: {},
  modalBodyProps: {},
  textWithInsertedData: {
    show: false,
    startText: '',
    insertedText: '',
    endText: '',
    endTextWhiteSpace: true,
  },
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (_state, { payload }:PayloadAction<ModalWrapperProps>) => payload,
    closeModal: (state) => {
      state.open = false;
    },
    resetModalState: () => initialState,
  },
});

export const {
  openModal,
  closeModal,
  resetModalState,
} = modalSlice.actions;

export default modalSlice.reducer;
