// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Imports
import axios, { AxiosError } from 'axios';
import { errorToast, successToast } from '@universities/store';
import Verify2FARequest from '../types/Verify2FARequest';

export const enableDashAdmin2FA = createAsyncThunk('goin/enableDashAdmin2FA', async (universityId: string) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/dashboard/dash-admin/enable-2fa/${universityId}`);
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
    }
    return Promise.reject(err);
  }
});

export const verifyDashAdmin2FA = createAsyncThunk(
  'goin/verifyDashAdmin2FA',
  async ({ universityId, otp }: Verify2FARequest) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/dashboard/dash-admin/verify-2fa/${universityId}`, { otp });

      if (response.data.verified) {
        successToast('Two-factor authentication has been successfully enabled!');
      } else {
        errorToast('Invalid code. Please try again.');
      }

      return {
        data: response.data,
      };
    } catch (err) {
      if (err instanceof AxiosError) {
        errorToast(err?.response?.data?.message || 'An error occurred. Please try again.');
      }
      return Promise.reject(err);
    }
  },
);

export const updateProfile = createAsyncThunk(
  'goin/updateProfile',
  async (
    {
      universityId,
      currentPassword,
      newPassword,
    }: {
      universityId: string,
      currentPassword: string,
      newPassword: string
    },
  ) => {
    try {
      const response = await axios.patch(`${process.env.REACT_APP_URL_ENV}/dashboard/dash-admin/${universityId}`, {
        currentPassword,
        newPassword,
      });

      if (response.status === 204) {
        successToast('Password changed successfully!');
      }

      return {
        data: response.data,
      };
    } catch (error) {
      let errMessage = error;
      if (error instanceof AxiosError) {
        errMessage = error?.response?.data.message || 'An error occurred. Please try again.';
      }
      return Promise.reject(errMessage);
    }
  },
);

export const myProfileSlice = createSlice({
  name: 'myProfile',
  initialState: { qrCode: '' },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(enableDashAdmin2FA.fulfilled, (state, action) => {
      state.qrCode = action.payload.data;
    });
  },
});

export default myProfileSlice.reducer;
