// ** Imports createContext function
import { createContext } from 'react';

// ** Imports createContextualCan function
import { createContextualCan } from '@casl/react';
import { AnyAbility } from '@casl/ability';

// ** Create Context
export const AbilityContext = createContext<AnyAbility>({} as AnyAbility);

// ** Init Can Context
export const Can = createContextualCan(AbilityContext.Consumer);
