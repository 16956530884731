import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { deleteToast, errorToast, successToast } from '@universities/store';
import { IMetaCommunityConfig } from '../types/IMetaCommunityConfig';

const initState: IMetaCommunityConfig[] = [];

export const getMetaCommunityConfigs = createAsyncThunk('metaCommunities/getCommunityQuestions', async (universityId: string) => {
  const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/universities/${universityId}/meta-community-configs`);
  return {
    data: response.data,
  };
});

export const createMetaCommunityConfig = createAsyncThunk('goin/createMetaCommunityConfig',
  async (req: IMetaCommunityConfig) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/universities/${req.universityId}/meta-community-config`,
        {
          metaCommunityId: req.metaCommunityId,
          daysUntilJoinEligibility: req.daysUntilJoinEligibility,
        });
      successToast('Meta community configuration successfully added!');
      return {
        data: response.data,
      };
    } catch (err) {
      if (err instanceof AxiosError) {
        errorToast(err?.response?.data?.message);
      }
      return Promise.reject(err);
    }
  });

export const deleteMetaConfig = async (universityId: string, _id?: string) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/universities/${universityId}/meta-community-configs/${_id}`);
    deleteToast('Meta community config successfuly deleted!');
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
    }
    return Promise.reject(err);
  }
};

export const metaCommunityConfigSlice = createSlice({
  name: 'metaCommunityConfig',
  initialState: {
    data: initState,
    currentMetaConfig: {} as IMetaCommunityConfig,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createMetaCommunityConfig.fulfilled, (state, action) => {
      state.data.push(action.payload.data);
      action.payload.data = 200;
    });
    builder.addCase(getMetaCommunityConfigs.fulfilled, (state, action) => {
      state.data = action.payload.data;
    });
  },
});

export default metaCommunityConfigSlice.reducer;
