// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from 'axios';
import { deleteToast, errorToast, successToast } from '@universities/store';
import FeedbackRequest from '@users/types/FeedbackRequest';
import FeedbackWrapper from '@goin-community/types/FeedbackWrapper';
import { StudentInclusionProps } from '../components/all-time-inclusion/StudentInclusion';

export const getFeedbackByUniversity = createAsyncThunk('goin/getFeedback', async (_id: string) => {
  const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/universities/${_id}/feedback`);
  return {
    data: response.data,
  };
});

export const addFeedback = createAsyncThunk('goin/addFeedback',
  async (req: FeedbackRequest) => {
    try {
      const response = await
      axios.post(`${process.env.REACT_APP_URL_ENV}/universities/${req.uniId}/feedback`, { userId: req.userId, content: req.content });
      successToast('Changes for feedback are successfully saved!');
      return {
        data: response.data,
      };
    } catch (err) {
      errorToast();
      return Promise.reject(err);
    }
  });

export const updateFeedback = createAsyncThunk('goin/updateFeedback',
  async (req: FeedbackRequest) => {
    try {
      const response = await
      axios.put(`${process.env.REACT_APP_URL_ENV}/universities/${req.uniId}/feedback/${req.userId}`, { content: req.content });
      successToast('Changes for feedback are successfully saved!');
      return {
        data: response.data,
      };
    } catch (err) {
      errorToast();
      return Promise.reject(err);
    }
  });

export const deleteFeedback = createAsyncThunk('goin/deleteFeedback',
  async (req:FeedbackRequest) => {
    try {
      await
      axios.delete(`${process.env.REACT_APP_URL_ENV}/universities/${req.uniId}/feedback/${req.userId}`);
      deleteToast('Feedback successfuly deleted!');
      return {
        data: { userId: req.userId, uniId: req.uniId },
      };
    } catch (err) {
      errorToast();
      return Promise.reject(err);
    }
  });

export const getStudentInclusion = createAsyncThunk('goin/getStudentInclusion', async (_id: string) => {
  const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/universities/${_id}/all-time-stats`);
  return {
    data: response.data,
  };
});

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: {
    universityFeedback: {} as FeedbackWrapper,
    studentInclusion: {} as StudentInclusionProps,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFeedbackByUniversity.fulfilled, (state, action) => {
      state.universityFeedback = action?.payload?.data;
    });
    builder.addCase(getStudentInclusion.fulfilled, (state, action) => {
      state.studentInclusion = action?.payload?.data;
    });
  },
});

export default feedbackSlice.reducer;
