// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios, { AxiosError } from 'axios';
import { deleteToast, errorToast, successToast } from '@universities/store';
import AdminRequest from '../types/AdminRequest';

export const getAdmins = createAsyncThunk('appusers/getAdmins', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/dashboard/dash-admin`);
    return {
      allAdmins:
      response.data,
    };
  } catch (err) {
    return rejectWithValue(err as AxiosError);
  }
});

export const addAdmin = async (req:AdminRequest) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/dashboard/dash-admin`, req);
    successToast('Admin successfully added!');
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
    }
    return Promise.reject(err);
  }
};

export const deleteAdmin = async (_id: string) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/dashboard/dash-admin/${_id}`);
    deleteToast('Admin successfuly deleted!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
};

export const impersonateAdmin = async (impersonateId: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/dashboard/dash-admin/${impersonateId}/impersonate`);
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
    }
    return Promise.reject(err);
  }
};

export const appusersSlice = createSlice({
  name: 'admins',
  initialState: {
    allAdmins: [],
    total: 1,
    params: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdmins.fulfilled, (state, action) => {
      state.allAdmins = action.payload.allAdmins;
    });
  },
});

export default appusersSlice.reducer;
