import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const sendResetEmail = async (email: string) => {
  const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/dashboard/dash-admin/reset-password`, { email });
  return {
    data: response.data,
  };
};

export const verifyToken = async (authToken: string | null) => {
  const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/dashboard/dash-admin/verify-email-token`, { params: { token: authToken } });
  return {
    data: response.data,
  };
};

export const updatePassword = async (password: string, authToken: string, actionType: string) => {
  const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/dashboard/dash-admin/password`,
    { password }, { params: { token: authToken, action: actionType } });
  return {
    data: response.data,
  };
};

export const universitiesSlice = createSlice({
  name: 'passwordManagement',
  initialState: {},
  reducers: {},
});

export default universitiesSlice.reducer;
