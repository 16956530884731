import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { errorToast, successToast } from '@universities/store';
import {
  StudyPlanLabel,
  StudyPlanPayload,
} from '@universities/types/StudyPlanLabel';

type InitialState = {
  labels: StudyPlanLabel[];
};

export const getStudyPlanLabels = createAsyncThunk(
  'goin/getStudyPlanLabels',
  async (_id: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_ENV}/universities/${_id}/study-plans`,
    );
    return {
      data: response.data,
    };
  },
);

export const updateStudyPlanLabels = createAsyncThunk(
  'goin/updateStudyPlanLabels',
  async ({
    universityId,
    payload,
  }: {
    universityId: string;
    payload: StudyPlanPayload;
  }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_URL_ENV}/universities/${universityId}/study-plans`,
        { ...payload },
      );
      successToast('Successfully updated!');
      return {
        data: response.data,
      };
    } catch (err) {
      errorToast();
      return Promise.reject(err);
    }
  },
);

const initialState: InitialState = {
  labels: [],
};

export const studyPlanSlice = createSlice({
  name: 'study-plan-labels',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStudyPlanLabels.fulfilled, (state, action) => {
      state.labels = action?.payload?.data;
    });
    builder.addCase(updateStudyPlanLabels.fulfilled, (state, action) => {
      state.labels = action?.payload?.data;
    });
  },
});

export default studyPlanSlice.reducer;
