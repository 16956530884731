import { useDispatch, useSelector } from 'react-redux';
import {
  Modal, ModalBody, ModalHeader,
} from 'reactstrap';
import { closeModal, resetModalState } from '@store/modal';
import ModalWrapperProps from '../types/ModalWrapperProps';

interface ModalState {
  modal:ModalWrapperProps
}

const ModalWrapper = () => {
  const modalState = useSelector((state:ModalState) => state.modal);
  const {
    open, Component, componentProps,
    title, subtitle, bodyContent,
    textWithInsertedData, modalProps,
    modalHeaderProps, modalBodyProps,
  } = modalState;
  const dispatch = useDispatch();

  return (
    <Modal
      isOpen={open}
      toggle={() => dispatch(closeModal())}
      onClosed={() => dispatch(resetModalState())}
      className="modal-dialog-centered"
      {...modalProps}
    >
      <ModalHeader
        className="bg-transparent"
        toggle={() => dispatch(closeModal())}
        {...modalHeaderProps}
      />
      <ModalBody className="px-sm-5 mx-50 pb-5" {...modalBodyProps}>
        <div>
          {bodyContent}
          <h1 className="text-center mb-1">{title}</h1>
          {subtitle && <p className="text-center">{subtitle}</p>}
          {textWithInsertedData?.show
            && (
              <p className="text-center">
                {textWithInsertedData.startText}
                <span className="text-primary">
                  &nbsp;
                  {textWithInsertedData.insertedText}
                  {textWithInsertedData.endTextWhiteSpace && <span>&nbsp;</span>}
                </span> {textWithInsertedData.endText}
              </p>
            )}
        </div>

        {Component && <Component {...componentProps} />}
      </ModalBody>
    </Modal>
  );
};

export default ModalWrapper;
