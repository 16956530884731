import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios, { AxiosError } from 'axios';

import PaginationParams from '@custom-components/types/PaginationParams';
import { errorToast, successToast } from '@universities/store';
import ManageWorldUniversityRequest from '../types/ManageWorldUniversityRequest';
import IWorldUniversity from '../types/IWorldUniversity';

const initState:IWorldUniversity[] = [];

export const getWorldUniversities = createAsyncThunk('goin/getWorldUniversities', async (params: PaginationParams) => {
  const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/api/world-universities`, { params });
  return {
    data: response.data.docs,
    count: response.data.count,
  };
});

export const updateWorldUniversity = createAsyncThunk('goin/updateWorldUniversity', async (req:ManageWorldUniversityRequest, { rejectWithValue }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/world-universities/${req._id}`, req);
    successToast('Changes for world university are successfully saved!');
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
      return rejectWithValue(err?.response?.status);
    }
    return Promise.reject(err);
  }
});

export const addWorldUniversity = createAsyncThunk('goin/addWorldUniversity', async (req:ManageWorldUniversityRequest, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/world-universities`, req);
    successToast('University successfully added!');
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
      return rejectWithValue(err?.response?.status);
    }
    return Promise.reject(err);
  }
});

export const universitiesSlice = createSlice({
  name: 'worldUniversities',
  initialState: {
    data: initState,
    count: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addWorldUniversity.fulfilled, (state, action) => {
      state.data.push(action.payload.data);
      action.payload.data = 200;
    });
    builder.addCase(addWorldUniversity.rejected, (_, action) => {
      action.payload = { data: action.payload };
    });
    builder.addCase(updateWorldUniversity.rejected, (_, action) => {
      action.payload = { data: action.payload };
    });
    builder.addCase(updateWorldUniversity.fulfilled, (state, action) => {
      const uniIndex = state.data.findIndex(
        (uni) => uni._id === action.payload.data._id,
      );
      if (uniIndex !== -1) {
        state.data[uniIndex] = action.payload.data;
        action.payload.data = 200;
      }
    });
    builder.addCase(getWorldUniversities.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.count = action.payload.count;
    });
  },
});

export default universitiesSlice.reducer;
